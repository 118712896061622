<script>
import { mapActions } from 'vuex'
import * as types from '@/domains/Management/vuex/mutations-types'
import managementMixin from '@/modules/Management/managementMixin'

export default {
  name: 'Management',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Action: () => import('@/components/general/Action'),
    FilterList: () => import('@/components/general/FilterList'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    Datatable: () => import('@/components/general/Datatable'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    EmptyMessage: () => import('@/components/general/EmptyMessage'),
    Pagination: () => import('@/components/general/Pagination'),
    Tabs: () => import('@/components/general/Tabs')
    // CheckboxItem: () => import('@/components/general/CheckboxItem')
  },

  mixins: [ managementMixin ],

  data () {
    return {
      customTabs: [
        // {
        //   text: this.$t('global:solution.recommendation.type.option.entr_profiles'),
        //   alias: 'entr_profiles'
        // },
        {
          text: this.$t('global:solution.recommendation.type.option.themes'),
          alias: 'themes'
        }
      ],

      activeTab: 'themes',
      themeList: null,
      paginationProfile: {
        query: {},
        filter: {},
        order: {
          active: 'DESC',
          name: 'ASC',
          alias: 'ASC'
        },

        page: 1,
        limit: 12,
        lastPage: 3
      },

      paginationTheme: {
        query: {},
        filter: {},

        order: {
          active: 'DESC',
          name: 'ASC'
        },

        page: 1,
        limit: 12,
        lastPage: 3
      },

      currentTheme: {}
    }
  },

  computed: {
    canWrite () {
      return this.getContextPermission('profiles') === 'write'
    },

    entrepreneurProfiles: {
      get () {
        return this.$store.state.Management.entrepreneurProfiles || []
      },

      set (newValue) {
        this.$store.commit(types.SET_MANAGEMENT_ENTREPRENEUR_PROFILES_LIST, newValue)
      }
    },

    hasThemeActiveParam () {
      return this.$route.params && this.$route.params.activeTab && this.$route.params.activeTab === 'themes'
    }
  },

  watch: {
    'paginationTheme.page' () {
      this.getListTheme()
    },

    'paginationProfile.page' () {
      this.getListEntrepreneurProfile()
    }
  },

  created () {
    this.paginationProfile.query = {}
    this.paginationTheme.query = {}

    if (this.hasThemeActiveParam || this.activeTab === 'themes') {
      this.activeTab = 'themes'
      this.getListTheme()
    } else {
      this.getListEntrepreneurProfile()
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptListEntrepreneurProfiles',
      'attemptToggleActiveEntrepreneurProfile',
      'attemptListThemes',
      'attemptToggleTheme',
      'attemptUpdateFeaturedTheme'
    ]),

    createNewTheme () {
      this.$router.push({ name: 'management.themes.manage' })
    },

    searchTheme (search) {
      this.paginationTheme.query = {
        name: search
      }

      this.getListTheme()
    },

    editTheme (id, theme) {
      this.$router.push({
        name: 'management.themes.manage',
        params: {
          id,
          theme
        }
      })
    },

    toggleTheme (id) {
      this.setFetching(true)

      this.attemptToggleTheme(id).finally(() => {
        this.setFetching(false)
        this.getListTheme()
      })
    },

    createNewProfile () {
      this.$router.push({ name: 'management.add' })
    },

    editProfile (id) {
      this.$router.push({
        name: 'management.edit',
        params: {
          id: id
        }
      })
    },

    toggleProfile (id) {
      this.setFetching(true)

      this.attemptToggleActiveEntrepreneurProfile(id).then((response) => {
        this.entrepreneurProfiles.forEach((entrepreneur, index) => {
          if (entrepreneur.id === id) {
            this.entrepreneurProfiles[index].active = response.data.active
            this.$forceUpdate()
          }
        })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    searchProfile (search) {
      this.paginationProfile.query = {
        name: search
      }

      this.firstPageProfile()
      this.getListEntrepreneurProfile()
    },

    prevPageProfile () {
      if (this.paginationProfile.page > 1) {
        this.paginationProfile.page -= 1
      }
    },

    nextPageProfile () {
      this.paginationProfile.page += 1
    },

    firstPageProfile () {
      this.paginationProfile.page = 1
    },

    lastPageProfile () {
      this.paginationProfile.page = this.paginationProfile.lastPage
    },

    prevPageTheme () {
      if (this.paginationTheme.page > 1) {
        this.paginationTheme.page -= 1
      }
    },

    nextPageTheme () {
      this.paginationTheme.page += 1
    },

    firstPageTheme () {
      this.paginationTheme.page = 1
    },

    lastPageTheme () {
      this.paginationTheme.page = this.paginationTheme.lastPage
    },

    getListEntrepreneurProfile () {
      this.setFetching(true)

      this.attemptListEntrepreneurProfiles(this.paginationProfile).then((response) => {
        this.paginationProfile.lastPage = response.lastPage
      }).finally(() => {
        this.setFetching(false)
      })
    },

    getListTheme () {
      this.setFetching(true)

      this.attemptListThemes(this.paginationTheme)
        .then(({ data }) => {
          this.themeList = data.data || []
          this.paginationTheme.page = data.actualPage
          this.paginationTheme.lastPage = data.lastPage
        })
        .catch(() => {
          this.themeList = []
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    changeTab (alias) {
      this.activeTab = alias
    },

    formatExtraFields (extraFields) {
      if (!extraFields || extraFields.length === 0) return '---'

      return extraFields.map(field => field.title).join(', ')
    }

    // updateFeaturedTheme (item) {
    //   this.setFetching(true)

    //   this.currentTheme = {
    //     id: item.id
    //   }

    //   this.attemptUpdateFeaturedTheme(this.currentTheme).then(() => {
    //     // this.setFeedback({ message: this.$t('themes:feedback.toggle.success') })
    //   })
    //     .catch(({ data }) => {
    //       if (data.error.message === 'theme_maximum_featured_reached') {
    //         this.setFeedback({ message: this.$t('themes:maximum.featured.reached') })
    //       } else {
    //         this.setFeedback({ message: this.$t(`management.themes:error.${data.error.message}`) })
    //       }

    //       item.featured = 0
    //     })
    //     .finally(() => {
    //       this.setFetching(false)
    //     })
    // }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('management:header.title')"
      :description="$t('management:header.description')"
      background="/assets/images/themes/default/png/management_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="tabLinks"
      />
    </ContentHeader>
    <FilterList>
      <Tabs
        slot="button"
        class="gamification-tabs"
        :links="customTabs"
        :alias-active="activeTab"
        use-alias
        @tabChange="changeTab"
      />
    </FilterList>
    <div
      v-if="activeTab === 'entr_profiles'"
      class="center management-content"
    >
      <FilterList>
        <Action
          v-if="canWrite"
          slot="button"
          type="button"
          primary
          large
          fixed-width
          :text="$t('management:btn.add')"
          @click="createNewProfile"
        />
        <!--
 TODO: FilterOrder
-->
        <FilterListSearch
          slot="search"
          hide-error-details
          @search="searchProfile"
        />
      </FilterList>
      <Datatable
        :key="entrepreneurProfiles.id + entrepreneurProfiles.active"
        :items="entrepreneurProfiles"
      >
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('management.list:datatable.header.1') }}
            </th>
            <th class="th">
              {{ $t('management.list:datatable.header.2') }}
            </th>
            <th
              class="th"
              width="100"
            >
              {{ $t('management.list:datatable.header.4') }}
            </th>
            <th
              class="th"
              width="40"
            />
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">{{ props.item.name }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ formatExtraFields(props.item.entrepreneurProfileQuestion) }}</span>
            </td>
            <td class="td">
              <span
                v-if="props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('management.list:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('management.list:inactive') }}</span>
            </td>
            <td
              class="td td-actions"
              width="40"
            >
              <Dropdown
                v-if="canWrite"
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  :text="$t('global:edit')"
                  @click="editProfile(props.item.id)"
                />
                <DropdownLink
                  v-if="props.item.active"
                  :text="$t('global:deactivate')"
                  @click="toggleProfile(props.item.id)"
                />
                <DropdownLink
                  v-else
                  :text="$t('global:activate')"
                  @click="toggleProfile(props.item.id)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>
      <Pagination
        :active-page="paginationProfile.page"
        :page-count="paginationProfile.lastPage"
        @firstPage="firstPageProfile"
        @lastPage="lastPageProfile"
        @nextPage="nextPageProfile"
        @previousPage="prevPageProfile"
      />
    </div>
    <div
      v-if="activeTab === 'themes'"
      class="center management-content"
    >
      <FilterList>
        <Action
          v-if="canWrite"
          slot="button"
          type="button"
          primary
          large
          fixed-width
          :text="$t('management.theme:btn.add')"
          @click="createNewTheme"
        />
        <FilterListSearch
          slot="search"
          hide-error-details
          @search="searchTheme"
        />
      </FilterList>
      <div class="center">
        <EmptyMessage v-if="themeList && themeList.length === 0">
          <h2>{{ $t('management.themes:list.empty.title') }}</h2>
          <p>{{ $t('management.themes:list.empty.message') }}</p>
        </EmptyMessage>
        <Datatable
          v-if="themeList && themeList.length > 0"
          :items="themeList"
        >
          <template slot="thead">
            <tr>
              <th class="th">
                {{ $t('management.theme.list:datatable.header.1') }}
              </th>
              <!--
 <th
                class="th"
                width="150"
              >
                {{ $t('management.theme.list:datatable.header.3') }}
              </th>
-->
              <th
                class="th"
                width="100"
              >
                {{ $t('management.theme.list:datatable.header.2') }}
              </th>
              <th
                class="th"
                width="40"
              />
            </tr>
          </template>
          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr>
              <td class="td">
                <span class="td-text bolder">{{ props.item.name }}</span>
              </td>
              <!--
 <td class="td">
                <CheckboxItem
                  :key="props.item.id"
                  :value="props.item.name"
                  :name="props.item.name"
                  :checked="props.item.featured"
                  :index="props.item.id"
                  :disabled="!props.item.active"
                  @change="updateFeaturedTheme(props.item)"
                />
              </td>
-->
              <td class="td">
                <span
                  v-if="props.item.active"
                  class="td-tag active-ribbon"
                >{{ $t('management.list:active') }}</span>
                <span
                  v-else
                  class="td-tag inactive-ribbon"
                >{{ $t('management.list:inactive') }}</span>
              </td>
              <td
                v-if="canWrite"
                class="td td-actions"
                width="40"
              >
                <Dropdown
                  icon="dots-vertical"
                  right
                >
                  <DropdownLink
                    v-if="canWrite"
                    :text="$t('global:edit')"
                    @click="editTheme(props.item.id, props.item)"
                  />
                  <DropdownLink
                    v-if="props.item.active && canWrite"
                    :text="$t('global:deactivate')"
                    @click="toggleTheme(props.item.id)"
                  />
                  <DropdownLink
                    v-if="!props.item.active && canWrite"
                    :text="$t('global:activate')"
                    @click="toggleTheme(props.item.id)"
                  />
                </Dropdown>
              </td>
            </tr>
          </template>
        </Datatable>
        <Pagination
          :active-page="paginationTheme.page"
          :page-count="paginationTheme.lastPage"
          @firstPage="firstPageTheme"
          @lastPage="lastPageTheme"
          @nextPage="nextPageTheme"
          @previousPage="prevPageTheme"
        />
      </div>
    </div>
    <RouterView />
  </div>
</template>

<style src="@/assets/styles/themes/default/management.css"></style>

<style src="@/assets/styles/themes/default/checkbox.css"></style>

<style>
.gamification-tabs {
  justify-content: flex-start;
  display: flex;
  gap: 40px;
}
</style>
